import Model from "../model"

import RouteLeg from "./routeLeg.model"

export default class Route extends Model {
    constructor(attr = null) {
        super();
        this.is_public = true;
        this.departure = null;
        this.destination = null;
        this.sid = null;
        this.sidTransition = "";
        this.sidWaypoints = [];
        this.star = null;
        this.starTransition = "";
        this.starWaypoints = [];
        this.iac = null;
        this.fleet_defaults = [];
        this.resetLegs();

        if (attr) {
            this.fill(attr);
        }
    }

    fill(attr) {
        if (attr.legs) {
            attr.legs = RouteLeg.createMany(attr.legs);
            attr.legs.push(new RouteLeg({
                index: attr.legs.length
            }));
        }
        super.fill(attr);
    }

    clearDeparture() {
        this.departure = null;
        this.clearSid();
    }

    clearSid() {
        this.sid = null;
        this.clearSidTransition();
    }

    resetLegs() {
        let leg = new RouteLeg();
        this.legs = [leg];
    }

    clearSidTransition() {
        this.sidTransition = "";
        this.sidWaypoints = [];
        this.resetLegs();
    }

    clearDestination() {
        this.destination = null;
        this.clearStar();
    }

    clearStar() {
        this.star = null;
        this.clearStarTransition();
    }

    clearStarTransition() {
        this.starTransition = "";
        this.starWaypoints = [];
        this.clearIac();
    }

    clearIac() {
        this.iac = null;
    }

    setLeg(index, leg) {
        if (this.legs.length > index) {
            this.legs.splice(-1 * (this.legs.length - index));
        }
        this.legs.push(leg);
    }

    get hasDeparture() {
        return this.departure !== null;
    }

    get hasSid() {
        return this.sid !== null;
    }

    get hasDestination() {
        return this.destination !== null;
    }

    get hasStar() {
        return this.star !== null;
    }

    get hasStarTransition() {
        return this.starTransition !== '';
    }

    get departureIcao() {
        if (this.departure) {
            return this.departure.icao;
        }
        return "-";
    }
    get departureLatitude() {
        if (this.departure) {
            return parseFloat(this.departure.latitude.toFixed(1));
        }
        return null;
    }
    get departureLongitude() {
        if (this.departure) {
            return parseFloat(this.departure.longitude.toFixed(1));
        }
        return null;
    }
    get destinationLatitude() {
        if (this.destination) {
            return parseFloat(this.destination.latitude.toFixed(1));
        }
        return null;
    }
    get destinationLongitude() {
        if (this.destination) {
            return parseFloat(this.destination.longitude.toFixed(1));
        }
        return null;
    }

    get sidName() {
        if (this.sid) {
            return this.sid.name;
        }
        return "-";
    }

    get destinationIcao() {
        if (this.destination) {
            return this.destination.icao;
        }
        return "-";
    }

    get starName() {
        if (this.star) {
            return this.star.name;
        }
        return "-";
    }
}