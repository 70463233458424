import Model from "../model"
import Service from "../../services/waypoint.service"

export default class RouteLeg extends Model {
    constructor(attr = null) {
        super();
        this.dct = true;
        this.kind = "DCT";
        this.waypoints = [];
        this.index = 0;
        this.airways = [];
        this.waypoint = null;
        this.airway = null;
        this.service = new Service();

        if (attr) {
            this.fill(attr);
            if (this.waypoint && !this.waypoint.of_client) {
                this.loadAirways();
            }
        }
    }

    loadAirways() {
        this.service.airways(this.waypoint.id).then(response => {
            this.airways = response.data.airways;
        })
    }

    get isFirst() {
        return this.index == 0;
    }

    get isSid() {
        return this.kind == 'SID';
    }

    get isStar() {
        return this.kind == 'STAR';
    }

    get isWP() {
        return (this.kind == 'WP');
    }

    get isDCT() {
        return (this.kind == 'DCT');
    }

    get hasWaypoint() {
        return (this.waypoint !== null && (this.waypoint.id || this.waypoint.of_client));
    }

    get hasWaypointOfClient () {
        if (this.waypoint !== null) {
            return this.waypoint.of_client
        }
        return false
    }

    get noWaypoints() {
        return (this.waypoints.length == 0);
    }

    get isConvertible() {
        if (this.isSid || this.isStar) {
            return false;
        }
        if (this.isWP && this.hasWaypoint) {
            return false;
        }
        if (this.isDCT && this.noWaypoints) {
            return false;
        }
        return true;
    }
    // ---------------------------- METODOS ---------------------------
    static createMany(collection) {
        return collection.map(function(item) {
            return new RouteLeg(item);
        });
    }
}