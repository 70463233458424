<template>
  <section v-cloak>
    <vue-headful :title="pageTitle"></vue-headful>
    <nav class="level">
      <div class="level-left">
        <div class="level-item">
          <div class="title is-4">Route {{$route.params.id}}</div>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <router-link :to="{path: '/routes/'+$route.params.id+'/edit'}">
            <a class="button is-info">Edit</a>
          </router-link>
        </div>
      </div>
    </nav>
    <div v-if="!route">Loading Please wait...</div>
    <div v-else>
      <nav class="level">
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Departure</p>
            <p class="title">{{route.departure_icao}}</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Sid</p>
            <p class="title">{{route.sid_name}}</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Sid Transition</p>
            <p class="title">{{route.sid_transition_name ? route.sid_transition_name : "-"}}</p>
          </div>
        </div>

        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Destination</p>
            <p class="title">{{route.destination_icao}}</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Star</p>
            <p class="title">{{route.star_name}}</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="heading">Star Transition</p>
            <p class="title">{{route.star_transition_name ? route.star_transition_name : "-"}}</p>
          </div>
        </div>
      </nav>

      <div class="columns">
        <div class="column">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Order</th>
                <th>Waypoint</th>
                <th>Kind</th>
                <th>Airway</th>
                <th>Latitude</th>
                <th>Longitude</th>
                <th>Distance</th>
                <th>Acumulated</th>
                <th>Course</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(waypoint, index) in route.waypoints" :key="waypoint.id" v-bind:class="{ 'is-danger': waypoint.kind != 'AP' && !waypoint.waypoint_id && !waypoint.is_local }">
                <td>{{index +1}}</td>
                <td>{{waypoint.name}}</td>
                <td>{{waypoint.kind}}</td>
                <td v-bind:class="{ 'is-danger': waypoint.waypoint_id && !waypoint.airway && waypoint.airway_ident}">
                  {{waypoint.airway ? waypoint.airway.ident : waypoint.airway_ident}}
                </td>
                <td>{{waypoint.latitude}}</td>
                <td>{{waypoint.longitude}}</td>
                <td>{{waypoint.distance}}</td>
                <td>{{waypoint.accumulated_distance}}</td>
                <td>{{waypoint.course}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="column">
          <GmapMap ref="mapRef" :zoom="zoom" :center="center" style="width: 100%; height: 800px">
            <GmapMarker
              v-for="waypoint in route.waypoints"
              :key="waypoint.id"
              :position="waypoint.position"
              :clickable="true"
              :icon="markerIcon(waypoint)"
              @click="center=waypoint.position"
            ></GmapMarker>

            <GmapPolyline
              v-if="path.length > 0"
              :path="path"
              :editable="false"
              :options="{strokeColor: '#000099', strokeWeight: 1}"
              ref="polyline"
            ></GmapPolyline>
          </GmapMap>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RouteService from "../../services/routes.service";
import Route from "../../models/route/route.model";

export default {
  name: "RouteShow",
  data() {
    return {
      pageTitle: "FLPN Aviasolutions - View Route",
      route: null,
      service: new RouteService(),
      zoom: 5,
      center: { lat: 10.9, lng: -97.76 }
    };
  },
  methods: {
    fitMapBounds() {
      if (this.path.length > 0) {
        this.$refs.mapRef.$mapPromise.then(map => {
          map.fitBounds(this.bounds);
        });
      }
    },
    markerIcon(waypoint) {
      // if (waypoint.kind == 'AP') {
      //   return 'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=•|363636';
      // }
      // if (waypoint.kind == 'SID' || waypoint.kind == 'STAR') {
      //   return 'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=•|209CEE';
      // }
      // return 'http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=•|FFDD57';
      if (waypoint.kind == "AP") {
        return (
          "https://chart.googleapis.com/chart?chst=d_map_spin&chld=1.4|0|363636|11|_|" +
          waypoint.name
        );
      }
      if (waypoint.kind == "SID" || waypoint.kind == "STAR") {
        return (
          "https://chart.googleapis.com/chart?chst=d_map_spin&chld=1.4|0|209CEE|11|_|" +
          waypoint.name
        );
      }
      return (
        "https://chart.googleapis.com/chart?chst=d_map_spin&chld=1.4|0|FFDD57|11|_|" +
        waypoint.name
      );
    }
  },
  computed: {
    minLatLng() {
      return new window.google.maps.LatLng(
        this.path.reduce((min, p) => Math.min(min, p.lat), this.path[0].lat),
        this.path.reduce((min, p) => Math.min(min, p.lng), this.path[0].lng)
      );
    },
    maxLatLng() {
      return new window.google.maps.LatLng(
        this.path.reduce((max, p) => Math.max(max, p.lat), this.path[0].lat),
        this.path.reduce((max, p) => Math.max(max, p.lng), this.path[0].lng)
      );
    },
    bounds() {
      let latLngBounds = new window.google.maps.LatLngBounds();
      latLngBounds.extend(this.minLatLng);
      latLngBounds.extend(this.maxLatLng);
      return latLngBounds;
    }
  },
  mounted() {
    this.pageTitle = `FLPN Aviasolutions - View Route ${this.$route.params.id}`;
    const loadingComponent = this.$buefy.loading.open({container: null})
    this.service.findOne(this.$route.params.id).then(
      ({ data }) => {
        this.route = new Route(data.data);
        this.path = this.route.waypoints.map(function(waypoint) {
          return waypoint.position;
        });
        loadingComponent.close();
      },
      () => {
        loadingComponent.close();
        this.$buefy.toast.open({
          message: "Route not found.",
          type: "is-danger"
        });
        this.$router.push("/routes");
      }
    );
    setTimeout(() => {
      this.fitMapBounds();
    }, 5000);
  }
};
</script>

<style scoped>
#map {
  height: 100%;
}
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}
tr.is-danger {
    background: #fc3c3c;
    color: #fff;
}
td.is-danger {
    background: #fc3c3c;
    color: #fff;
}
</style>
